export const SampleUsers = [
    {
        "id": 1,
        "password": "@johndoe!",
        "last_login": null,
        "is_superuser": false,
        "username": "johndoe",
        "first_name": "John",
        "last_name": "Doe",
        "email": "grantcstutor@gmail.com",
        "is_staff": false,
        "is_active": true,
        "date_joined": "2024-06-23T12:39:36.246503Z",
        "role": "student",
        "groups": [],
        "user_permissions": []
    },
    {
        "id": 2,
        "password": "@writerone!",
        "last_login": null,
        "is_superuser": false,
        "username": "writerone",
        "first_name": "Writer",
        "last_name": "One",
        "email": "writerone@mail.com",
        "is_staff": true,
        "is_active": true,
        "date_joined": "2024-06-23T12:41:13.600942Z",
        "role": "staff",
        "groups": [],
        "user_permissions": []
    },
    {
        "id": 3,
        "password": "@ogonsarigo!",
        "last_login": null,
        "is_superuser": false,
        "username": "gonsarigo",
        "first_name": "George",
        "last_name": "Onsarigo",
        "email": "georgeonsarigo5@gmail.com",
        "is_staff": false,
        "is_active": true,
        "date_joined": "2024-06-23T15:15:22.934121Z",
        "role": "staff",
        "groups": [],
        "user_permissions": []
    },
    {
        "id": 4,
        "password": "pbkdf2_sha256$720000$CiPz5feHyUlvVYhkElLj1z$9OCiyWQpREPMHxniMid+UBypUflpJfJyhX7XlVVw2s8=",
        "last_login": "2024-08-01T10:02:58.887544Z",
        "is_superuser": true,
        "username": "grandcanyon",
        "first_name": "",
        "last_name": "",
        "email": "",
        "is_staff": true,
        "is_active": true,
        "date_joined": "2024-08-01T10:02:02.068059Z",
        "role": "",
        "groups": [],
        "user_permissions": []
    },
]