// /src/pages/auth/login/loginPage.jsx

import React from "react";
import { LoginForm } from "./loginForm";


export const LoginPage = () => {

    return (
        <>
            <LoginForm />
        </>
    )
}