export const SampleReviews = [
    {
        "id": 1,
        "review": "Great work",
        "created_at": "2024-08-01T11:20:40.103687Z",
        "updated_at": "2024-08-01T11:20:40.103687Z",
        "order": 1
    },
    {
        "id": 2,
        "review": "Fantastic job",
        "created_at": "2024-08-01T11:20:50.728295Z",
        "updated_at": "2024-08-01T11:20:50.728295Z",
        "order": 2
    },
    {
        "id": 3,
        "review": "Did several revisions but overall great work",
        "created_at": "2024-08-01T11:21:08.788389Z",
        "updated_at": "2024-08-01T11:21:08.788389Z",
        "order": 3
    },
    {
        "id": 4,
        "review": "Couldn't be any better",
        "created_at": "2024-08-01T11:21:22.915788Z",
        "updated_at": "2024-08-01T11:21:22.915788Z",
        "order": 4
    }
]