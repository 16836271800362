export const SampleFaqs = [
  {
    "category": "General",
    "question": "What is the purpose of this website?",
    "answer": "This website offers a variety of handmade African jewelry, available for purchase online."
  },
  {
    "category": "General",
    "question": "How do I contact customer support?",
    "answer": "You can contact customer support via email at support@jewelrystore.com or call us at 1-800-123-4567."
  },
  {
    "category": "General",
    "question": "Do you offer international shipping?",
    "answer": "Yes, we offer international shipping to many countries. Shipping fees and delivery times vary based on your location."
  },
  {
    "category": "Account",
    "question": "How do I create an account?",
    "answer": "You can create an account by clicking the 'Sign Up' button at the top right of the homepage and filling out the registration form."
  },
  {
    "category": "Account",
    "question": "How can I reset my password?",
    "answer": "To reset your password, click on 'Forgot Password' on the login page and follow the instructions to receive a password reset link."
  },
  {
    "category": "Account",
    "question": "How do I update my account information?",
    "answer": "You can update your account information by logging in, going to your profile settings, and editing your details."
  },
  {
    "category": "Orders",
    "question": "How can I track my order?",
    "answer": "You can track your order by logging into your account and viewing the order details under the 'My Orders' section."
  },
  {
    "category": "Orders",
    "question": "What should I do if I receive a damaged item?",
    "answer": "If you receive a damaged item, please contact our customer support within 48 hours of delivery for assistance."
  },
  {
    "category": "Orders",
    "question": "Can I cancel or modify my order after placing it?",
    "answer": "You can cancel or modify your order within 24 hours of placing it by contacting customer support."
  },
  {
    "category": "Profile",
    "question": "How do I upload a profile picture?",
    "answer": "To upload a profile picture, go to your profile settings and click on 'Upload Picture' to choose an image from your device."
  },
  {
    "category": "Profile",
    "question": "How do I change my username?",
    "answer": "You can change your username by visiting the profile settings section and entering a new username."
  },
  {
    "category": "Profile",
    "question": "Can I delete my account?",
    "answer": "Yes, you can delete your account by contacting customer support. Please note that this action is irreversible."
  },
  {
    "category": "Transactions",
    "question": "What payment methods do you accept?",
    "answer": "We accept various payment methods including credit cards, PayPal, and bank transfers."
  },
  {
    "category": "Transactions",
    "question": "How do I request a refund?",
    "answer": "To request a refund, please contact our customer support with your order details within 30 days of purchase."
  },
  {
    "category": "Transactions",
    "question": "Is it safe to enter my credit card information on your site?",
    "answer": "Yes, our website uses SSL encryption to ensure that your credit card information is secure during transactions."
  }
]
