import img1 from "../images/apple-and-books.jpeg";
import img2 from "../images/gaelle-marcel-L8SNwGUNqbU-unsplash.jpg";
import img3 from "../images/syd-wachs-slItfWbhijc-unsplash.jpg";
import img4 from "../images/wulan-sari-mHjvJqvj1XE-unsplash.jpg";
import img5 from "../images/patrick-tomasso-Oaqk7qqNh_c-unsplash.jpg";
import img6 from "../images/maya-maceka-yW-Qgw_IJXg-unsplash.jpg";
import img7 from "../images/hongbin-KBn3vqsFkDo-unsplash.jpg";
import img8 from '../images/joseph-corl-LyVNOdlGEE0-unsplash.jpg';
import img9 from "../images/apple-and-books.jpeg";
import img0 from "../images/gaelle-marcel-L8SNwGUNqbU-unsplash.jpg";

const loremText = ("Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis distinctio doloribus fugit minima obcaecati perferendis reiciendis repellendus tempore vitae! Alias ex, nihil. Fugiat iste quam quasi! Debitis fugit quidem voluptatem! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis distinctio doloribus fugit minima obcaecati perferendis reiciendis repellendus tempore vitae! Alias ex, nihil. Fugiat iste quam quasi! Debitis fugit quidem voluptatem! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis distinctio doloribus fugit minima obcaecati perferendis reiciendis repellendus tempore vitae! Alias ex, nihil. Fugiat iste quam quasi! Debitis fugit quidem voluptatem!")
export const SamplePosts = [
    {
        cover_img: img1,
        title: "Understanding Nursing Ethics",
        date_published: "10:15 AM, 1st June 2024",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        text: loremText,
    },
    {
        cover_img: img2,
        title: "Healthcare Policy Changes in 2024",
        date_published: "2:30 PM, 3rd June 2024",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        text: loremText,
    },
    {
        cover_img: img3,
        title: "Effective Communication in Nursing",
        date_published: "9:00 AM, 5th June 2024",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        text: loremText,
    },
    {
        cover_img: img4,
        title: "Advancements in Medical Technology",
        date_published: "11:45 AM, 8th June 2024",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        text: loremText,
    },
    {
        cover_img: img5,
        title: "Patient Care Best Practices",
        date_published: "3:20 PM, 10th June 2024",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        text: loremText,
    },
    {
        cover_img: img6,
        title: "Handling Nursing Workload",
        date_published: "8:00 AM, 12th June 2024",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        text: loremText,
    },
    {
        cover_img: img7,
        title: "Mental Health in Healthcare Professionals",
        date_published: "4:50 PM, 15th June 2024",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        text: loremText,
    },
    {
        cover_img: img8,
        title: "The Future of Telemedicine",
        date_published: "7:30 AM, 18th June 2024",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        text: loremText,
    },
    {
        cover_img: img9,
        title: "Building Patient Trust",
        date_published: "12:00 PM, 20th June 2024",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        text: loremText,
    },
    {
        cover_img: img0,
        title: "Ethical Dilemmas in Nursing",
        date_published: "9:47 AM, 22nd June 2024",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        text: loremText,
    }
];

