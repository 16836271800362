import fill_form_img from "../icons/form.png"
import make_order_img from "../icons/order-now.png"
import make_payment_img from "../icons/credit-card.png";
import solution_img from "../icons/solution.png";
import feedback_img from "../icons/talking.png";
import revision_img from "../icons/check.png";
export const Guidelines = [
    {
        id: "1",
        title: "Fill Form",
        description: "Provide necessary details by filling out a request form.",
        icon: fill_form_img,
    },
    {
        id: "2",
        title: "Make Order",
        description: "Place your order to receive a personalized price quote.",
        icon: make_order_img,
    },
    {
        id: "3",
        title: "Make Payment",
        description: "Securely make your payment through our offered platforms.",
        icon: make_payment_img,
    },
    {
        id: "4",
        title: "Receive Solution",
        description: "Access your solution once it is ready.",
        icon: solution_img,
    },
    {
        id: "5",
        title: "Solution Revision",
        description: "Request revisions if need be to ensure satisfaction.",
        icon: revision_img,
        link: "link_to_plagiarism",
    },
    {
        id: "6",
        title: "Give Feedback",
        description: "Give feedback to help us improve our services.",
        icon: feedback_img,
    },
    
]