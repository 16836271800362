export const SampleAssignments = [
    {
        "id": 1,
        "topic": "Managing Infectious Diseases",
        "type": "research paper",
        "level": "undergraduate",
        "language": "English US",
        "pages": 5,
        "status": "pending",
        "urgency": "low",
        "instructions": "Write a 5 page research paper on managing infectious diseases.",
        "deadline": "2024-08-22 13:06:00",
        "sources": 4,
        "revisions": 2,
        "price": 275,
        "style": "chicago",
        "payment_status": "pending",
        "created_at": "2024-08-01T10:54:25.037056Z",
        "updated_at": "2024-08-01T10:54:25.037056Z",
        "user": 4,
        "assigned_to": 2
    },
    {
        "id": 2,
        "topic": "Covid-19",
        "type": "essay",
        "level": "undergraduate",
        "language": "english US",
        "pages": 3,
        "status": "pending",
        "urgency": "medium",
        "instructions": "Write a 3 page essay on Covid-19",
        "deadline": "2024-08-22 13:06:00",
        "sources": 3,
        "revisions": 2,
        "style": "mla",
        "price": 100,
        "payment_status": "pending",
        "created_at": "2024-08-01T10:55:49.471369Z",
        "updated_at": "2024-08-01T10:55:49.471369Z",
        "user": 4,
        "assigned_to": 2
    },
    {
        "id": 3,
        "topic": "Nursing Capstone Project",
        "type": "capstone project",
        "level": "undergraduate",
        "language": "english US",
        "pages": 19,
        "status": "pending",
        "urgency": "medium",
        "instructions": "Select a topic and write a 20-page capstone project on it, following the guidelines on Canvas.",
        "deadline": "2024-08-22 13:06:00",
        "sources": 8,
        "revisions": 2,
        "style": "apa",
        "price": 1000,
        "payment_status": "pending",
        "created_at": "2024-08-01T10:57:06.507305Z",
        "updated_at": "2024-08-01T10:57:06.507305Z",
        "user": 4,
        "assigned_to": 2
    },
    {
        "id": 4,
        "topic": "Administering drugs to aged people",
        "type": "class discussion",
        "level": "undergraduate",
        "language": "english US",
        "pages": 1,
        "status": "complete",
        "urgency": "high",
        "instructions": "Write one page response to today's class discussion: Administering drugs to aged people",
        "deadline": "2024-08-22 13:06:00",
        "sources": 5,
        "revisions": 2,
        "style": "turabian",
        "price": 200,
        "payment_status": "pending",
        "created_at": "2024-08-01 10:58:10.917752Z",
        "updated_at": "2024-08-01 10:58:10.917752Z",
        "user": 4,
        "assigned_to": 2
    }
]