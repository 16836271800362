import medicine_img from "../icons/medicine.png";
import finance_img from "../icons/investment.png";
import legal_img from "../icons/law.png";
import engineering_img from "../icons/engineering.png";
import social_img from "../icons/book.png";
import arts_img from "../icons/hobby.png";
import education_img from "../icons/education.png";


export const Subjects = [
    {
        id: 0,
        title: "Medicine",
        icon: medicine_img,
    },
    {
        id: 1,
        title: "Finance",
        icon: finance_img,
    },
    {
        id: 2,
        title: "Legal",
        icon: legal_img,
    },
    {
        id: 3,
        title: "Engineering",
        icon: engineering_img,
    },
    {
        id: 4,
        title: "Social",
        icon: social_img,
    },
    {
        id: 5,
        title: "Arts",
        icon: arts_img,
    },
    {
        id: 6,
        title: "Education",
        icon: education_img,
    },

];
export const SubjectCategories = [
    {

    }
]

