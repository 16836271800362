import site_logo from "../logos/logo.png"
import site_logo_purple from "../logos/logo-purple.png"
export const SiteData = {
    site_name: "Grand Canyon Tutoring Site",
    logo: site_logo,
    logo_purple: site_logo_purple,
    site_abbrev: 'GCTS',
    slogan: "a safe portal to academic understanding",
    site_colours: {
        main_colour: "#FFF15C",
        accent_colour: "#8C59D9",
    }
}

export const Contacts =  {
    email: "support@mail.com",
    telephone1: {
        code: "123",
        number: "456 78 90",
    },
    telephone2: {
        code: "123",
        number: "456 78 90",
    },
}