export const SamplePayments = [
    {
        "id": 1,
        "amount": 100,
        "transaction_id": "T01",
        "payment_method": "PayPal",
        "payment_status": "Completed",
        "created_at": "2024-08-01 09:17:39.988738",
        "updated_at": "2024-08-01 09:17:39.988738",
        "user": 1,
        "order": null
    },
    {
        "id": 2,
        "amount": 50,
        "transaction_id": "T02",
        "payment_method": "PayPal",
        "payment_status": "Completed",
        "created_at": "2024-08-01 09:17:58.864036",
        "updated_at": "2024-08-01 09:17:58.864036",
        "user": 1,
        "order": null
    },
    {
        "id": 3,
        "amount": 500,
        "transaction_id": "T03",
        "payment_method": "Payoneer",
        "payment_status": "Completed",
        "created_at": "2024-08-01 09:18:18.401757",
        "updated_at": "2024-08-01 09:18:18.401757",
        "user": 1,
        "order": null
    }
]