export const SampleRequests = [
        {
            id: 1,
            type: "Research Paper",
            level: "Undergraduate",
            pages: 12,
            sources: 5,
            urgency: 'High',
            deadline: {
                data: {
                    day: 7,
                    month: 8,
                    year: 24,
                },
                time: {
                    minute: 45,
                    hour: 12,
                }
            }
        },
    {
            id: 2,
            type: "Technical Paper",
            level: "Undergraduate",
            pages: 20,
            sources: 12,
            urgency: 'Medium',
            deadline: {
                data: {
                    day: 16,
                    month: 10,
                    year: 24,
                },
                time: {
                    minute: 0,
                    hour: 0,
                }
            }
        },
    {
            id: 3,
            type: "Quizlet",
            level: "Undergraduate",
            pages: 20,
            sources: 12,
            urgency: 'Medium',
            deadline: {
                data: {
                    day: 16,
                    month: 10,
                    year: 24,
                },
                time: {
                    minute: 0,
                    hour: 0,
                }
            }
        },
]